import React from 'react';

import './App.css';
import mHeader from './header_bg.png';

import {db} from './utils/firebase';
import useState from 'react';

function Magazine() {

    // const [magazineList, setMagazineList] = useState("");

    // db.collection('magazines').get()
    //     .then(docs=>{
    //         console.log("GOT DA STUFF");
    //         setMagazineList(prev => prev = docs.data());
    //     }).catch(e=>console.log(e));
    // console.log(magazineList);
    let elem = (
        <div>
            <div className="magHeader">
                <img src={mHeader} />
            </div>
            {/* <div className="magCardList">
                {magazineList.map(magazine => <div>{magazine["title"]}</div>)}
            </div> */}
            <div className="errorDiv">
                Dear readers, we have taken down the magazine due to a technical error, it will be back online shortly
            </div>
        </div>
    );
    
    return elem;
}

export default Magazine;